.terms {
  font-size: 16px;
  line-height: 20px;
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  h1 {
    text-align: center;
    font-size: 30px;
    line-height: 30px;
    padding: 10px 0;
  }
  h2,
  h3 {
    font-size: 27px;
    line-height: 30px;
    padding: 10px 0;
  }
  p {
    padding: 10px 0;
  }

  .closeBtn{
    position: absolute;
    top: 17px;
    right: 23px;
    width: 26px;
    height: 26px;
    color: #444;
  }
}

.overlay.signIn{
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Arial;
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  z-index: 10000;
  align-items: center;
  justify-content: center;
    
    .privacyContainer{
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      z-index: 999;
      text-align: center;
      overflow-y: scroll;

      .container{
        max-width: 600px;
        text-align: left;
        margin: 0 auto;
        padding: 20px;
      }
    }
.privacy{
  font-size: 13px;
  line-height: 23px;
  text-align: center;
  padding-top: 10px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
}
  .signInPopup {
    width: auto;
    min-width: 300px;
    background-color: #fff;
    border-radius: 5px;
    padding: 35px;
    position: relative;
    box-sizing: border-box;

    .messageList {
      padding: 10px;
      font-size: 13px;
      line-height: 16px;
      list-style-type: disc;
      padding: 10px 0px 10px 11px;
      width: 295px;

      li {
        padding-bottom: 7px;
        &.error {
          color: #dc3545;
        }
      }
    }

    .closeBtn {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      text-decoration: none;
      color: #000;

      &:hover {
        text-decoration: none;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }

    h1 {
      color: #2b2d38;
      font-size: 21px;
      text-align: center;
      line-height: 28px;
      padding: 10px 0 !important;
    }

    .blockWrapper {
      position: relative;
      .inputLabel {
        // color: #dc3545;
        position: absolute;
        top: -7px;
        background-color: #fff;
        left: 9px;
        padding: 0 2px;
        font-size: 13px;
      }

      &.passwordRequirements {
        font-size: 12px;
        line-height: 14px;
        padding-bottom: 15px;
        margin-top: -16px;
        width: 308px;
      }

      input {
        border: 1px solid #000;
        padding: 10px 15px;
        border-radius: 5px;
        margin-bottom: 20px;
        font-size: 18px;
        display: block;
        width: 100%;
        box-sizing: border-box;
      }
      &.error {
        .inputLabel {
          color: #dc3545;
        }
        input {
          border: 1px solid #dc3545;
        }
      }
    }

    .signInOr {
      text-align: center;
      font-size: 12px;
      margin-top: 16px;
      margin-bottom: 16px;
      text-transform: uppercase;
    }
    .signInBtn {
      background-color: #15c39a;
      color: #fff;
      font-size: 14px;
      line-height: 16px;
      height: 48px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
    .forgotPassword {
      text-align: right;
    }
    .socialBtn {
      font-size: 14px;
      height: 48px;
      justify-content: center;
      line-height: 16px;
      margin-bottom: 8px;
      background: #fff;
      border: 1px solid #e7e9f5;
      border-radius: 4px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #444;
      text-decoration: none;

      svg {
        margin-right: 10px;
      }

      &:hover {
        border-color: #aaa;
        color: #000;
        text-decoration: none;
      }
    }

    hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid hsl(0, 0%, 80%);
      margin: 1em 0;
      padding: 0;
    }

    a {
      font-size: 13px;
      line-height: 16px;
      color: #0052cc;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .rightLink {
      float: right;
    }

    .captchaWrapper {
      margin: -49px auto 0;
      position: relative;
      z-index: 161;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .captchaOverlay {
      background: rgba(0, 0, 0, 0.8);
      z-index: 160;
      position: fixed; //absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .icon-success {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 65px;
        height: 65px;
        padding: 4px 0 16px;
        polyline {
          -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
          animation: checkmark 0.25s ease-in-out 0.7s backwards;
        }

        circle {
          -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
          animation: checkmark-circle 0.6s ease-in-out backwards;
        }
        circle#colored {
          -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
          animation: colored-circle 0.6s ease-in-out 0.7s backwards;
        }
      }
    }
  }
}
@media (max-width: 382px) {
  .overlay {
    .signInPopup {
      width: 100% !important;
      min-width: 100% !important;
      border-radius: 0;
      padding: 20px;
      position: relative;
    }
  }
}
